export const SessionStorage = () => {
    let data = sessionStorage.getItem("Swastik_login") || "{}";
    return data !== "{}" && Object.values(data).length > 0 ? true : false;
};

export const getStoredUserPrivileges = () => {
    let data = sessionStorage.getItem('Swastik_Privileges') || '{}';
    let roleDetails = data ? JSON.parse(data) : {}
    let permissions = roleDetails?.permissions
    return { roleDetails, permissions }
}