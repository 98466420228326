import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarDropdown = ({ item }: any) => {
  const pathname = useLocation();
  const navigate = useNavigate();

  const handleClick = (route: string) => {
    navigate(route)
  };

  
  const getActive =(items:any)=>{
    return `/${pathname.pathname?.split("/")[1]}` === items?.route
  }
  

  return (
    <>
      <ul className=" flex flex-col">
        {item.map((item: any, index: number) => (
          <li key={index}>
            <p
              onClick={() => handleClick(item.route)}
              className={`${getActive(item) ? "bg-lightRed dark:bg-meta-4 inter-500-97180C border-r border-r-rose-600" : "hover:text-[#1D2939] inter-500-667085-sb "} cursor-pointer text-sm  group relative flex items-center gap-2.5 rounded-sm pl-12 py-2   duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4`}
            >
              {item.label}
            </p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SidebarDropdown;
