
import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "./SidebarItem";
import ProductionSvg from "../../assets/production_house.svg";
import DashboardSvg from "../../assets/dashboardIcon.svg";
import ProjectSvg from "../../assets/projectIcon.svg";
import DashboardActive from "../../assets/dashboadActive.svg";
import AdminUserIcon from "../../assets/usersSidebar.svg";
import AdminUserIconActive from "../../assets/usersSidebarActive.svg";
import AllPersonalIcon from "../../assets/allPersonalSidebar.svg";
import VendorIcon from "../../assets/vendorSidebar.svg";
import ProductIcon from "../../assets/productSidebar.svg";
import LocationIcon from "../../assets/manageLocationSidebar.svg";
import CalanderIcon from "../../assets/calendarSidebar.svg";
import ReportsIcon from "../../assets/reportsSidebar.svg";
import ReportsIconActive from "../../assets/reportsIconActive.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { Divider } from "@mantine/core";
import CollapseIcon from "../../assets/align-left.svg";
import { sideMenu } from "../../services/apiSlices/staticApi";
import ProductMasterIcon from "../../assets/productMaster.svg";
import ProductRequisitionIcon from "../../assets/productRequisition.svg";
import { getStoredUserPrivileges } from "../../helper/utils";
import ResourcesIcon from "../../assets/resources.svg";
import ResourcesActive from "../../assets/resourceActive.svg";

const menuGroups = [
  {
    name: "",
    menuItems: [
      {
        icon: <img src={ProductionSvg} alt="sidebar" />,
        iconActive: <img src={ProductionSvg} alt="sidebar" />,
        label: "Production Houses",
        route: "#",
        layout: "",
        key: ""
      },
      {
        icon: <img src={DashboardSvg} alt="DashboardSvg" />,
        iconActive: <img src={DashboardActive} alt="DashboardActive" />,
        label: "Dashboard",
        route: "/dashboard",
        layout: "dashboard",
        key: "dashboard"
      },
      {
        icon: <img src={ProjectSvg} alt="ProjectSvg" />,
        iconActive: <img src={ProjectSvg} alt="ProjectSvg" />,
        label: "Project",
        route: "#",
        key: "project",
        children: [
          { label: "Manage Serials", route: "/serials" },
          { label: "Manage Channels", route: "/channels" },
          { label: "VFX Master", route: "/VFX-Master" },
        ],
      },
      {
        icon: <img src={AdminUserIcon} alt="AdminUserIcon" />,
        iconActive: <img src={AdminUserIconActive} alt="AdminUserIconActive" />,
        label: "Admin Users",
        route: "/admin-users",
        layout: "admin-users",
        key: "admin_users"
      },
      {
        icon: <img src={AllPersonalIcon} alt="AllPersonalIcon" />,
        iconActive: <img src={AllPersonalIcon} alt="AdminUserIconActive" />,
        label: "All Personnel",
        route: "#",
        layout: "all-personal",
        key: "all_personnel",
        children: [
          { label: "VC Users", route: "/vc-users" },
          { label: "FC Users", route: "/fc-users" },
          { label: "EC Users", route: "/ec-users" },

        ],

      },
      {
        icon: <img src={VendorIcon} alt="AllPersonalIcon" />,
        iconActive: <img src={VendorIcon} alt="AdminUserIconActive" />,
        label: "Vendor",
        route: "#",
        layout: "vendor",
        key: "vendor",
        children: [
          { label: "All Vendors", route: "/vendors" }
        ],
      },
      {
        icon: <img src={ProductMasterIcon} alt="ProductMasterIcon" />,
        iconActive: <img src={ProductIcon} alt="AdminUserIconActive" />,
        label: "Product Master",
        route: "/product-master",
        layout: "product",
        key: "product_master"
      },
      {
        icon: <img src={ProductRequisitionIcon} alt="ProductRequisitionIcon" />,
        iconActive: <img src={ProductIcon} alt="AdminUserIconActive" />,
        label: "Product Requisition",
        route: "/product-requisition",
        layout: "product",
        key: "product_requisition"
      },
      {
        icon: <img src={LocationIcon} alt="LocationIcon" />,
        iconActive: <img src={LocationIcon} alt="LocationIcon" />,
        label: "Manage Locations",
        route: "/location",
        layout: "manage-location",
        key: "manage_locations"
      },
      {
        icon: <img src={CalanderIcon} alt="CalanderIcon" />,
        iconActive: <img src={CalanderIcon} alt="CalanderIcon" />,
        label: "Schedule",
        route: "#",
        layout: "schedule",
        key: "schedules",
        children: [
          { label: "Manage Schedules", route: "/schedules" },
          { label: "Manage Units", route: "/manageUnits" }
        ],
      },
      {
        icon: <img src={ReportsIcon} alt="ReportsIcon" />,
        iconActive: <img src={ReportsIconActive} alt="ReportsIconActive" />,
        label: "Reports",
        route: "/reports",
        layout: "reports",
        key: "reports"
      },
      {
        icon: <img src={ResourcesIcon} alt="ResourcesIcon" />,
        iconActive: <img src={ResourcesActive} alt="ResourcesActive" />,
        label: "Resources",
        route: "/resources",
        layout: "resources",
        key: "resources"
      },
    ],
  },
];

const Sidebar = () => {
  const name = useSelector((state: RootState) => state.static.pageName);
  const [pageName, setPageName] = useState(name);
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);
  const { permissions, roleDetails } = getStoredUserPrivileges();
  useEffect(() => {
    setPageName(name)
  }, [name])

  useEffect(() => {
    dispatch(sideMenu(collapse))
  }, [collapse])


  useEffect(() => {
    window.screen.width <= 1199
      ? setCollapse(true)
      : setCollapse(false);
  }, [window.screen.width]);

  function detectWindowSize() {
    window.innerWidth <= 1199 ? setCollapse(true) : setCollapse(false);
  }

  window.onresize = detectWindowSize;


  return (
    <aside
      className={`asideBar border bg-white fixed left-0 top-0 z-9999 flex h-screen ${collapse ? 'w-52px  ' : 'w-60 '}  flex-col overflow-y-hidden lg:translate-x-0 ${true ? "translate-x-0" : "-translate-x-full"
        }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      {/* <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <a href="/">
          <img
            width={176}
            height={32}
            src={require("../../assets/Logo.svg")}
            alt="Logo"
          />
        </a>

        <button
          // onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div> */}
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="py-4 mt-16">
          {menuGroups.map((group, groupIndex) => (
            <div key={groupIndex}>
              <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
                {group.name}
              </h3>

              <ul className="mb-6 flex flex-col gap-1.5">
                {group.menuItems.map((menuItem, menuIndex) => {
                  if (permissions && permissions?.includes(menuItem?.key)) {
                    return (
                      <SidebarItem
                        key={menuIndex}
                        item={menuItem}
                        pageName={pageName}
                        setPageName={setPageName}
                      />
                    )
                  }
                })}
              </ul>
            </div>
          ))}
        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
      <Divider className="" />
      <img src={CollapseIcon} alt="CollapseIcon" onClick={() => setCollapse(!collapse)} className={`${collapse && 'rotate-180 '} h-5 w-5 mt-4 mb-4 ml-auto mr-3 cursor-pointer`} />

    </aside>
  );
};

export default Sidebar;
