import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sideMenu: false,
    loader: false,
    pageName:""
};

const userSlice = createSlice({
    name: "statics",
    initialState,
    reducers: {
        sideMenu: (state, action) => {
            state.sideMenu = action.payload;
        },
        loader: (state, action) => {
            state.loader = action.payload;
        },
        pageName: (state, action) => {
            state.pageName = action.payload;
        },
    },
});
export const { sideMenu, loader,pageName } = userSlice.actions;
export default userSlice.reducer;
