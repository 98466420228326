import {
    FetchArgs,
    createApi,
    fetchBaseQuery,
    retry,
    FetchBaseQueryArgs, FetchBaseQueryError, FetchBaseQueryMeta, BaseQueryFn

} from "@reduxjs/toolkit/query/react";
import { store } from "../store";
import { loader } from "./staticApi";
import { notifications } from "@mantine/notifications";

interface CustomFetchBaseQueryArgs extends FetchArgs {
    customRedirect?: boolean;
}

export const BaseUrl = `${window.location.origin}/api/v1/`
const baseQuery = fetchBaseQuery({
    // baseUrl: 'https://swastik.gapsmiths.com/',
    baseUrl: BaseUrl,
    prepareHeaders: async (headers) => {
        const Token: any = sessionStorage.getItem("Swastik_login");
        const email = sessionStorage.getItem('Swastik_login_email');
        // headers.set("Content-type", "application/json");
        if (Token && Token !== "undefined") {
            headers.set("Authorization", "Bearer " + Token);
        }
        if(email){
            headers.set("email",email);

        }
        return headers;
    },
    // credentials: "include",
    // mode: "no-cors",
    // redirect: "manual",


});





const customFetchBaseQuery: BaseQueryFn<string | CustomFetchBaseQueryArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = async (args, api, extraOptions) => {

    // console.log('args', args);
    store.dispatch(loader(true));
    const result = await baseQuery(args, api, extraOptions);
    const tokenExpired:any = result?.error?.data;
    console.log('api', api, 'XX: result', result,"tokenExpired",tokenExpired);
    if (result) {
        store.dispatch(loader(false));
    }
    if (api.endpoint === 'login') {
        sessionStorage.setItem("Swastik_login", "Swastik_login_success")
        // window.location.href = `${window.location.origin}/dashboard`;
    }
    if ((result.error as FetchBaseQueryError)?.status === 302) {
        store.dispatch(loader(false));
    }
    if(tokenExpired?.detail === 'Invalid token.' || tokenExpired?.error === 'Invalid token.'|| tokenExpired?.detail === 'Permission not granted for this action.'){
        // window.location.href="/"
        // sessionStorage.removeItem("Swastik_login")
        notifications.show({
            title: 'User login',
            message: tokenExpired?.detail === 'Permission not granted for this action.' ? tokenExpired?.detail : 'Session Expired.' ,
            position: 'top-right',
            color: 'red',
        })
    }

    if(result?.error?.status === "FETCH_ERROR"){
        notifications.show({
            title: 'Error',
            message:'Failed to fetch' ,
            position: 'top-right',
            color: 'red',
        })
    }
    return result;
};

//used for token handle and common response and error

const staggeredBaseQueryWithBailOut = retry(
    async (args: string | FetchArgs, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        // console.log("XX:", result);
        return result;
    },
    {
        maxRetries: 0,
    }
);

export const baseApi = createApi({
    reducerPath: "baseApiReducer",
    baseQuery: customFetchBaseQuery,
    endpoints: () => ({}),
    // keepUnusedDataFor:0,
    tagTypes: [
        "VcUser",
        'fcUser',
        'ecUser',
        'adminUser',
        "dashboard",
        "UserRole",
        "channel",
        "vfx",
        "serials",
        "productMaster",
        "units",
        "category",
        "location",
        "vendor",
        "schedules",
        "scheduleUnits",
        "misc"
    ]
});
