
import { Navigate, Outlet } from "react-router-dom";
import NavBar from "../components/navBar";
import Sidebar from "../components/Sidebar";
import { SessionStorage } from "../helper/utils";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";

function PrivateRoute() {
    let isAuth = SessionStorage();
    const sideMenuCollapse = useSelector((state: RootState) => state.static.sideMenu);
    
    return isAuth ? (
        <>
            <NavBar />
            <Sidebar />
            <main className={`${sideMenuCollapse ? "ml-10" :"ml-60"}  mt-16 mb-4 pt-8 pl-4 pr-4 bg-bgLightGrey`}>
                <Breadcrumb/>
                <Outlet />
            </main>
        </>
    ) : (
        <Navigate to={{ pathname: "/" }} />
    );
}

export default PrivateRoute;