import { useLocation } from "react-router-dom";
import HomeSvg from "../../assets/home.svg";
import RightArrow from "../../assets/rightArrowLight.svg";
import React, { useEffect, useState } from "react";
import { BreadCrumbsProps } from "../../types/breadCrumbs";

const convertBreadcrumb = (string: string) => {
  return string
    ?.replace(/-/g, ' ');
};

const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);


  useEffect(() => {
    if (location) {
      const linkPath = location.pathname.split('/');
      linkPath.shift();

      const pathArray: any = linkPath.map((path, i) => {
        // Remove the query parameter from the last segment of the path
        const cleanPath = i === linkPath.length - 1 ? path.split('?')[0] : path;

        return { breadcrumb: cleanPath, href: '/' + linkPath.slice(0, i + 1).join('/') };
      });
      setBreadcrumbs(pathArray);
    }
  }, [location]);


  return (
    <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <nav aria-label="breadcrumbs">
        <ol className="flex ">
          <li>
            <a className="font-medium flex gap-1" href="/">
              <img src={HomeSvg} alt="HomeSvg" className="" />
            </a>
          </li>
          {breadcrumbs.map((breadcrumb: BreadCrumbsProps, i: number) => {
            return (
              <li key={breadcrumb.href} className="flex items-center">
                <img src={RightArrow} alt="RightArrow" className="h-6 w-5" />
                <a href={breadcrumb.href} className={`${breadcrumbs?.length === i + 1 ? " pl-1 pr-2 rounded-md bg-[#EAECF0] " : " "}   capitalize inter-600-344054 flex gap-1   text-sm`}>
                  {convertBreadcrumb(breadcrumb.breadcrumb)}
                </a>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
