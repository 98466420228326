import { useLocation, useNavigate } from "react-router-dom";
import SidebarDropdown from "./SidebarDropdown";
import ActiveRedDot from "../../assets/redDot.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store";
import { Popover, Tooltip } from "@mantine/core";

const SidebarItem = ({ item, pageName, setPageName }: any) => {

  const navigate = useNavigate();
  const sideMenuCollapse = useSelector((state: RootState) => state.static.sideMenu);


  const handleClick = (route: string) => {
    navigate(route)
    const updatedPageName =
      pageName !== item.label ? item.label : "";
    return setPageName(updatedPageName);
  };

  const pathname = useLocation();

  const isActive = (item: any) => {
    if (item.layout === pathname?.pathname?.split("/")[1]) return true;
    else if (item?.route === `/${pathname?.pathname?.split("/")[1]}`) return true
    if (item.children) {
      return item.children.some((child: any) => isActive(child));
    }
    return false;
  };

  const isItemActive = isActive(item);

  return (
    <>
      <li className="relative">
        {isItemActive && <img src={ActiveRedDot} alt="" className="absolute z-10 right-px top-4" />}
        <Tooltip label={item.label} disabled={!sideMenuCollapse} position="right" color="#97180C">
          {
            sideMenuCollapse ?
              <p
                // href={item.route}
                onClick={() => handleClick(item.route)}
                className={`${isItemActive ? "bg-lightRed dark:bg-meta-4 inter-500-97180C border-r border-r-rose-600" : "hover:text-[#1D2939] inter-500-667085-sb "} cursor-pointer text-sm  group relative flex items-center gap-2.5 rounded-sm pl-4 py-2   duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4`}
              >
                <Popover width={200} position="right" withArrow shadow="md">
                  <Popover.Target>
                    {isItemActive ? item?.iconActive : item.icon}
                  </Popover.Target>
                  <Popover.Dropdown>
                  {
                    item?.children?.map((res: any,index:number) => {
                      return (
                          <a key={index} href={res?.route}  className="mt-3 cursor-pointer text-sm inter-500-667085-sb mt-3">{res?.label}<br/></a>
                        )
                      })
                    }
                    </Popover.Dropdown>
                </Popover>

              </p>
              :
              <p
                // href={item.route}
                onClick={() => handleClick(item.route)}
                className={`${isItemActive ? "bg-lightRed dark:bg-meta-4 inter-500-97180C border-r border-r-rose-600" : "hover:text-[#1D2939] inter-500-667085-sb "} cursor-pointer text-sm  group relative flex items-center gap-2.5 rounded-sm pl-4 py-2   duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4`}
              >
                {isItemActive ? item?.iconActive : item.icon}
                {!sideMenuCollapse && item.label}
                {!sideMenuCollapse && item.children && (
                  <svg
                    className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${pageName === item.label && "rotate-180"
                      }`}
                    width="15"
                    height="15"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                      fill=""
                    />
                  </svg>
                )}
              </p>
          }


        </Tooltip>
        {!sideMenuCollapse && item.children && (
          <div
            className={`translate transform overflow-hidden ${pageName !== item.label && "hidden"
              }`}
          >
            <SidebarDropdown item={item.children} />
          </div>
        )}
      </li>
    </>
  );
};

export default SidebarItem;
