import { Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./appLayout/publicLayout";
import PrivateRoute from "./appLayout/privateLayout";
import { PrivateRouteComponents, PublicRouteComponents } from "./constants/routes";
import { Suspense } from "react";
import Loader from "./components/loader";
import { useSelector } from "react-redux";
import { RootState } from "./services/store";
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';

function App() {
  const loader = useSelector((state: RootState) => state.static.loader);


  const getPrivateRoutes = () => {
    return PrivateRouteComponents.map((prop: any, key: number) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };

  const getPublicRoutes = () => {
    return PublicRouteComponents.map((prop, key) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };


  return (
    <Suspense fallback={<Loader/>}>
      {loader && <Loader/>}
      <Notifications />
      <Routes>
        <Route element={<PublicRoute />}>{getPublicRoutes()}</Route>
        <Route element={<PrivateRoute />}>{getPrivateRoutes()}</Route>
        <Route index element={<Navigate to="/login" replace />} />
      </Routes >
    </Suspense>

  );
}

export default App;
